@import '../../../../scss/theme-bootstrap';

.gnav-perfume-block {
  max-width: 100%;
  margin: auto;
  &__title {
    margin-bottom: 0.8em;
    font-size: 13px;
  }
  &__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    &-item {
      text-align: center;
      display: block;
      width: 33.33%;
      &-image {
        margin-bottom: 15px;
        margin-#{$rdirection}: 15px;
      }
      &-link {
        text-align: $ldirection;
        a {
          color: inherit;
          text-decoration: none;
          text-transform: initial;
          font-size: 12px;
          font-weight: 300;
        }
      }
      &--single {
        width: 100%;
        .gnav-perfume-block__content-item-image-img {
          width: 100%;
        }
      }
    }
  }
}
